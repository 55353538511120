import styled from "styled-components";

import vars from "variables";

import arrow from "assets/Common/CollectionPage/Page/right-blue.svg";

const Root = styled.div`
  .featured img {
    border-radius: 10px;
  }

  .featured h2 {
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 155%;
  }

  li img {
    border-radius: 10px;
  }

  li strong {
    margin: 15px 0 3px;
    display: block;
    text-transform: uppercase;
  }

  li p {
    height: 60px;
    overflow: hidden;
  }

  a.more {
    padding-right: 20px;
    font-weight: bold;
    text-transform: uppercase;
    background: url(${arrow}) no-repeat center right;
  }

  ${vars.desktop.mediaQuery} {
    .featured,
    ul {
      display: flex;
    }

    .featured {
      margin-top: 100px;
    }

    .featured img {
      width: 725px;
    }

    .featured div {
      padding: 50px 0 0 65px;
    }

    .featured h2 {
      margin: 15px 0 20px;
      font-size: 1.7rem;
    }

    .featured label {
      padding: 4px 8px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.7rem;
      letter-spacing: 1px;
      background: rgb(48, 50, 64);
      border-radius: 3px;
    }

    .featured p {
      margin-bottom: 45px;
      color: #aaa;
      line-height: 160%;
    }

    .featured a.button {
      padding-left: 50px;
      padding-right: 50px;
      letter-spacing: 0.5px;
    }

    ul {
      margin: 50px 0;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    li {
      margin-bottom: 40px;
      flex: 0 0 calc(33.3% - 18px);
    }

    li img {
      width: 100%;
      height: 280px;
      object-fit: cover;
    }

    li strong {
      font-size: 0.8rem;
    }

    li p {
      margin-bottom: 35px;
      color: ${vars.colors.grey};
      line-height: 140%;
    }

    a.more {
      font-size: 0.8rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    .featured {
      margin-top: 55px;
    }

    .featured img,
    li img {
      width: 100%;
      height: 235px;
      object-fit: cover;
    }

    .featured label {
      display: none;
    }

    .featured h2,
    li strong {
      margin: 18px 0 15px 0;
      font-size: 0.85rem;
    }

    .featured p,
    li p {
      margin-bottom: 25px;
    }

    ul {
      margin: 32px 0 50px;
    }

    li {
      margin-bottom: 32px;
    }
  }
`;

export { Root };

import React from "react";
import { Root } from "./style";

import PageHeader from "components/Common/PageHeader";
import Page from "./Page";

const CollectionPage = ({
  pageContext: { collectionPage, pageIndex, perPage },
}) => {
  return (
    <Root className="body-width">
      <PageHeader page={collectionPage} />
      <Page
        collectionPage={collectionPage}
        perPage={perPage}
        pageIndex={pageIndex}
      />
    </Root>
  );
};

export default CollectionPage;

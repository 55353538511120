import React from "react";
import { Root } from "./style";

import { Link } from "gatsby";

import chunk from "lodash/chunk";

import SiteImage from "components/Common/SiteImage";
import Paginator from "components/Common/Paginator";

import { toResourceURL } from "utils";

const Page = ({ collectionPage, perPage, pageIndex }) => {
  const resourceURL = toResourceURL(collectionPage.path);
  const pages = chunk(collectionPage.collection, perPage).map((group) => ({
    featured: group.shift(),
    items: group,
  }));

  pageIndex || (pageIndex = 1);
  const { featured, items } = pages[pageIndex - 1];

  return (
    <Root>
      <div className="featured">
        <Link to={resourceURL(featured)}>
          <SiteImage image={featured.image} />
        </Link>
        <div>
          <label>{featured.category}</label>
          <h2>
            <Link to={resourceURL(featured)}>{featured.title}</Link>
          </h2>
          <p>{featured.intro}</p>
          <Link className="button blue desktop" to={resourceURL(featured)}>
            Learn more
          </Link>
          <Link className="mobile more" to={resourceURL(featured)}>
            Learn more
          </Link>
        </div>
      </div>
      <ul>
        {items.map((resource) => (
          <li key={resource.id}>
            <Link to={resourceURL(resource)}>
              <SiteImage image={resource.image} />
            </Link>
            <strong>
              <Link to={resourceURL(resource)}>{resource.title}</Link>
            </strong>
            <p>{resource.intro}</p>
            <Link className="more" to={resourceURL(resource)}>
              Learn more
            </Link>
          </li>
        ))}
      </ul>
      <Paginator
        path={collectionPage.path}
        perPage={perPage}
        pageIndex={pageIndex}
        totalCount={collectionPage.collection.length}
      />
    </Root>
  );
};

export default Page;
